<template>
  <img alt="Vue logo" src="./assets/logo.svg">
  <business-area
    title="Bespoke Software"
    description="Are you running a small or medium sized business? 
    We help you with bespoke software that takes your business to the next level."
  ></business-area>
  <business-area 
    title="e-commerce Advisory"
    description="We provide a tool to help you determine:
    which products to drop, which to re-stock, which are being targeted by competition,
    all at your fingertips."
  ></business-area>
</template>

<script>
import BusinessArea from './components/BusinessArea.vue'

export default {
  name: 'App',
  components: {
    BusinessArea
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
