<template>
  <div class="barea">
    <h2 @click="toggleDetails">{{ title }}</h2>
    <p v-if="expanded">{{description}}</p>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'description'
  ],
  data() {
      return {
          expanded: false
      }
  },
  methods: {
      toggleDetails() {
          this.expanded = !this.expanded;
      }
  }
}
</script>

<style scoped>
div.barea {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: 2rem auto;
  max-width: 40rem;
}
</style>
